<template>
  <div v-if="hasActions"
       class="content-actions">
    <quick-add-button v-if="content.enableQuickAddForContent && isCartEnabled"
                      class="quick-add-btn"></quick-add-button>
    <div class="dropdown"
         data-unit="actions-dropdown"
         :class="{'is-active': toggleDropdown}">
      <div class="new-dropdown-trigger">
        <button class="button" aria-controls="dropdown-menu" @click="toggleDropdown = !toggleDropdown">
          <span class="icon is-small">
            <i v-if="toggleDropdown" class="fas fa-times"></i>
            <i v-else class="fas fa-angle-down"></i>
          </span>
        </button>
      </div>
      <div class="dropdown-menu" id="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <div v-if="canEdit"
               class="dropdown-item">
            <router-link data-unit="edit-button"
                         class="dropdown-item"
                         target="_blank"
                         :to="editLink">
              <span class="icon fas fa-edit" />
              <span>{{ $t('edit') }}</span>
            </router-link>
          </div>
          <div v-if="canFavorite"
               class="dropdown-item"
               data-unit="favorite-button"
               @click="addToFavorites">
            <span :class="favoriteStarClass"/>
            <span>{{ $t('favorites') }}</span>
          </div>
          <div v-if="canExport"
               class="dropdown-item"
               data-unit="export-button"
               @click="exportContent">
            <span class="icon fas fa-file-export" />
            <span>{{ $t('export') }}</span>
          </div>
          <span v-if="canExportStandalone">
            <div v-if="hasEnabledIframeSupport && isBasicIFrame"
                 class="dropdown-item custom-download"
                 data-unit="download-button"
                 @click="customDownload(content.contentUrl)">
              <span class="icon fas fa-download" />
              <span>{{ $t('download') }}</span>
            </div>
            <a v-else
               :href="content.contentUrl"
               class="dropdown-item custom-download"
               data-unit="download-button">
              <span class="icon fas fa-download" />
              <span>{{ $t('download') }}</span>
            </a>
          </span>
          <div v-if="reportAnIssueEnabled"
               class="dropdown-item"
               @click="reportAnIssue">
            <span class="icon fas fa-exclamation-triangle" /><span>{{ $t('reportAnIssue') }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="actions-buttons buttons pr-2">
      <router-link v-if="canEdit"
                   class="button"
                   :to="editLink"
                   target="_blank">
        <span class="icon fas fa-edit" />
        <span>{{ $t('edit') }}</span>
      </router-link>
      <button v-if="canFavorite"
              class="button"
              @click="addToFavorites">
        <span :class="favoriteStarClass" />
        <span>{{ $t('favorites') }}</span>
      </button>
      <button v-if="canExport"
              class="button"
              @click="exportContent">
        <span class="icon fas fa-file-export" />
        <span>{{ $t('export') }}</span>
      </button>
      <div v-if="canExportStandalone"
           class="pr-2">
        <button v-if="hasEnabledIframeSupport && isBasicIFrame"
                class="button"
                @click="customDownload(content.contentUrl)">
          <span class="icon fas fa-download"></span>
          <span>{{ $t('download') }}</span>
        </button>
        <a v-else
           class="button"
           download
           :href="content.contentUrl">
          <span class="icon fas fa-download" />
          <span>{{ $t('download') }}</span>
        </a>
      </div>
      <button v-if="reportAnIssueEnabled"
              class="button"
              @click="reportAnIssue">
        <span class="icon fas fa-exclamation-triangle" />
        <span>{{ $t('reportAnIssue') }}</span>
      </button>
    </div>
    <add-to-favorites-modal v-if="addToFavoritesOpen"
                            :key="favoriteTarget.id"
                            :media="favoriteTarget"
                            @cancel="addToFavoritesOpen = false"
                            @favoritesUpdated="fetchFavorited()" />
    <export-modal v-if="exportModalOpen"
                  :disable-redirect="false"
                  :locale-hidden="false"
                  @exportContent="handleExport"
                  @cancel="exportModalOpen = false" />
    <report-an-issue-modal v-if="reportAnIssueModalOpened"
                           @cancel="reportAnIssueModalOpened = false" />
  </div>
</template>

<script>
import AddToFavoritesModal from './AddToFavoritesModal'
import ExportModal from './ExportModal'
import http from '@/http'
import ReportAnIssueModal from './ReportAnIssueModal'
import { customDownload } from '@/helpers/customDownload'
import { directive as onClickaway } from 'vue3-click-away'
import { mapGetters } from "vuex"
import BulmaIcon from "@/components/common/BulmaIcon.vue"
import BulmaButtonIcon from "@/components/common/BulmaButtonIcon.vue"
import QuickAddButton from "@/components/common/QuickAddButton.vue"

export default {
  name: 'ContentActions',
  props: {
    content: {
      type: Object,
      required: true
    },
    book: {
      type: Object,
      default: () => {}
    },
    hasDisabledPrinting: {
      type: Boolean
    },
    hasDisabledBookPrinting: {
      type: Boolean
    },
    reportAnIssueEnabled: {
      type: Boolean
    }
  },
  directives: {
    onClickaway
  },
  components: {
    QuickAddButton,
    BulmaButtonIcon,
    BulmaIcon,
    AddToFavoritesModal,
    ExportModal,
    ReportAnIssueModal
  },
  data () {
    return {
      addToFavoritesOpen: false,
      exportModalOpen: false,
      favorited: false,
      isBasicIFrame: false,
      reportAnIssueModalOpened: false,
      toggleDropdown: false
    }
  },
  watch: {
    book: {
      immediate: true,
      handler () {
        this.fetchFavorited()
      }
    }
  },
  computed: {
    ...mapGetters({
      hasEnabledIframeSupport: 'user/hasEnabledIframeSupport',
      isCartEnabled: 'user/isCartEnabled'
    }),
    isDetailsPage() {
      return this.$route.path.includes( 'details')
    },
    isPublisher () {
      return this.$store.getters['user/isPublisher']
    },
    hasActions () {
      return this.canEdit || this.canFavorite || this.canExport || this.canExportStandalone || this.reportAnIssueEnabled
    },
    canEdit () {
      return this.isPublisher && this.editLink !== null
    },
    canExport () {
      if (this.hasDisabledPrinting) return false

      const contentType = this.content.contentType
      if (!!this.book && contentType === 'book') return !this.hasDisabledBookPrinting
      return ['chapter', 'illustration'].includes(contentType)
    },
    canExportStandalone () {
      const canExport = !this.hasDisabledPrinting
      const contentType = this.content.contentType

      if (contentType === 'document' || contentType === 'image' || contentType === 'video') {
        return canExport
      }
      return false
    },
    canFavorite () {
      const standardUser = !this.$store.state.user.generic
      return standardUser && !!this.favoriteTarget
    },
    editLinkName () {
      switch (this.content.type) {
        case 'part':
          return 'PartEditor'
        case 'page':
          return 'PageEditor'
        case 'media':
          return 'MediaEditor'
        default:
          return null
      }
    },
    editLinkParams () {
      return {
        id: this.content.id
      }
    },
    editLink () {
      const name = this.editLinkName
      const params = this.editLinkParams

      if (name && params) {
        return { name, params }
      }

      return null
    },
    favoriteTarget () {
      if (this.book) {
        return this.book
      } else if (this.content.type === 'media') {
        return this.content
      }
      return null
    },
    favoriteStarClass () {
      return [
        'icon',
        'fa-star',
        this.favorited ? 'fas' : 'far'
      ]
    }
  },
  methods: {
    customDownload,
    addToFavorites () {
      this.addToFavoritesOpen = true
    },
    exportContent () {
      this.exportModalOpen = true
    },
    reportAnIssue () {
      this.reportAnIssueModalOpened = true
    },
    async fetchFavorited () {
      // Identifiers are available differently between different content.
      const id = this.content?.id || (this.book && this.book.id)

      if (!id) return

      try {
        const { data } = await http.get(`medias/${id}/media-shelves`)
        this.favorited = data && data.length
      } catch (error) {
        console.log('error :>> ', error)
      }
    },
    async handleExport (payload) {
      await this.$store.dispatch('content/exportContent', payload)
    }
  },
  mounted () {
    // Applicable to normal logins/TEK; widgets must be handled separately
    this.isBasicIFrame = window !== window.top && !this.$store.getters['widgets/isWidget']
  }
}
</script>

<style scoped lang="scss">
.custom-download {
  align-content: center;
  height: 56px;
  padding-left: 24px;
}
.content-actions {
  align-self: baseline;
  display: flex;
  flex-direction: row;

  .quick-add-btn {
    margin-right: .5em;
  }
}
.dropdown-menu {
  right: 0;
  left: unset;
}
.is-active {
  .button, .button:focus, .button.is-focused {
    border-color: hsl(217, 71%, 53%);
    color: #363636;
    box-shadow: 0 0 0 0.125em rgba(62, 122, 204, 0.25);
  }
}
.actions-buttons.buttons {
  justify-content: flex-end;
  flex-wrap: nowrap;
}
.button:focus, .button.is-focused, .button {
  box-shadow: unset;
  border-color: hsl(0, 0%, 71%);
  color: unset;
}
.dropdown-item a {
  margin: 0;
  padding: 0;
}
.dropdown-item:hover {
  background-color: hsl(0, 0%, 98%);
  color: #3E7ACC;

  a:hover {
    background-color: unset;
  }
}
@media only screen and (max-width: 768px) {
  .content-actions > .button > span {
    display: none;
  }
}

@media only screen and (min-width: 1025px) {
  .new-dropdown-trigger, .dropdown {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .actions-buttons {
    display: none;
  }
}
</style>
